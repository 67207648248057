// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-confirmation-jsx": () => import("./../../../src/pages/confirmation.jsx" /* webpackChunkName: "component---src-pages-confirmation-jsx" */),
  "component---src-pages-eye-care-jsx": () => import("./../../../src/pages/eye-care.jsx" /* webpackChunkName: "component---src-pages-eye-care-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-request-jsx": () => import("./../../../src/pages/request.jsx" /* webpackChunkName: "component---src-pages-request-jsx" */)
}

